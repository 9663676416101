import {useState} from "react";
import {Link} from "react-router-dom";

function ObfuscatedEmail({email}) {
    const [assembledEmail, setAssembledEmail] = useState(null);

    const handleClick = () => {
        setAssembledEmail(`mailto:${email}`);
    };

    return (
        <a href={assembledEmail} onClick={handleClick}>
            kontakt at tum-phoenix.de
        </a>
    );
}

function Apply() {
    // const [expandedJob, setExpandedJob] = useState(null);
    //
    // const handleJobClick = (jobId) => {
    //   if (expandedJob === jobId) {
    //     setExpandedJob(null);
    //   } else {
    //     setExpandedJob(jobId);
    //   }
    // };

    return (
        <div className="glue-page bookmark">
            <div className="page-title">
                <h1 className="page-title__title glue-headline glue-headline--headline-2">
                    Build cool stuff with us!
                </h1>
            </div>
            <div className="page-cover">
                <div className="page-cover__description">
                    <p>
                        The current primary focus of the club is in programming 1:10 scale cars for racing or
                        simulated road driving, such as F1Tenth and Bosch Future Mobility.
                        Although we occasionally have some small amount of mechanical or electrical work to do, it
                        is infrequent.
                        Anyone who would like to join and take part in one of these competitions should be prepared
                        to come to meetings regularly,
                        to invest a few additional hours every week into the project, and to communicate
                        proactively.
                    </p>
                    <p>
                        We are always open to starting smaller projects for beginners, but we expect the new members
                        to then drive that project themselves and to come with concrete ideas.<br/>

                        The most important attribute for anyone who would like to join the club is a can-do attitude
                        and the willingness for self-driven learning.
                    </p>
                    <p>
                        Some experience in the following is beneficial:
                        <ul>
                            <li>C++</li>
                            <li>Python</li>
                            <li>Linux</li>
                            <li>ROS2</li>
                            <li>Motion, computer vision & localization algorithms</li>
                        </ul>
                    </p>
                    <p>
                        If you are interested in joining, please send us an email at <ObfuscatedEmail email="kontakt@tum-phoenix.de">kontakt at tum-phoenix.de</ObfuscatedEmail>
                    </p>
                </div>
            </div>
            {/*<div className="card-list job-list">*/
            }
            {/*  <ul className="glue-cards glue-spacer-4-top glue-spacer-4-bottom glue-grid">*/
            }
            {/*    <li*/
            }
            {/*      className="glue-grid__col glue-grid__col--span-18 glue-grid__col--span-6-md glue-grid__col--span-18-lg glue-grid__col--span-18-xl">*/
            }
            {/*      <a className="glue-card apply-card glue-card--list"*/
            }
            {/*         href="#"*/
            }
            {/*         onClick={() => handleJobClick('marketingManager')}>*/
            }
            {/*        <div className="glue-card__inner">*/
            }
            {/*          <div className="glue-card__content">*/
            }
            {/*            <div className="glue-grid">*/
            }
            {/*              <p*/
            }
            {/*                className="glue-headline glue-headline--headline-5 glue-grid__col glue-grid__col--span-12 glue-grid__col--span-12-md">*/
            }
            {/*                Marketing Manager </p>*/
            }
            {/*              <p*/
            }
            {/*                className="glue-caption glue-grid__col glue-grid__col--span-4 glue-grid__col--span-12-md">Organization</p>*/
            }
            {/*            </div>*/
            }
            {/*            {expandedJob === 'marketingManager' && (*/
            }
            {/*              <div className="job-description__details">*/
            }
            {/*                <p>*/
            }
            {/*                  Help us keep our social media channels up to date and keep people informed*/
            }
            {/*                  about current events, our projects and collaborations, ...*/
            }
            {/*                </p>*/
            }
            {/*              </div>*/
            }
            {/*            )}*/
            }
            {/*          </div>*/
            }
            {/*        </div>*/
            }
            {/*      </a>*/
            }
            {/*    </li>*/
            }
            {/*    <li*/
            }
            {/*      className="glue-grid__col glue-grid__col--span-18 glue-grid__col--span-6-md glue-grid__col--span-18-lg glue-grid__col--span-18-xl">*/
            }
            {/*      <a className="glue-card apply-card glue-card--list"*/
            }
            {/*         href="#"*/
            }
            {/*         onClick={() => handleJobClick('hrManager')}>*/
            }
            {/*        <div className="glue-card__inner">*/
            }
            {/*          <div className="glue-card__content">*/
            }
            {/*            <div className="glue-grid">*/
            }
            {/*              <p*/
            }
            {/*                className="glue-headline glue-headline--headline-5 glue-grid__col glue-grid__col--span-12 glue-grid__col--span-12-md">*/
            }
            {/*                HR Manager </p>*/
            }
            {/*              <p*/
            }
            {/*                className="glue-caption glue-grid__col glue-grid__col--span-4 glue-grid__col--span-12-md">Organization</p>*/
            }
            {/*            </div>*/
            }
            {/*            {expandedJob === 'hrManager' && (*/
            }
            {/*              <div className="job-description__details">*/
            }
            {/*                <p>*/
            }
            {/*                  Help us find new members who would be a good fit to our team and make their*/
            }
            {/*                  onboarding process as smooth as possible. You will also represent TUM*/
            }
            {/*                  Phoenix at occasional fairs and social events. Required skills: good*/
            }
            {/*                  communication and organization skills.*/
            }
            {/*                </p>*/
            }
            {/*              </div>*/
            }
            {/*            )}*/
            }
            {/*          </div>*/
            }
            {/*        </div>*/
            }
            {/*      </a>*/
            }
            {/*    </li>*/
            }
            {/*    <li*/
            }
            {/*      className="glue-grid__col glue-grid__col--span-18 glue-grid__col--span-6-md glue-grid__col--span-18-lg glue-grid__col--span-18-xl">*/
            }
            {/*      <a className="glue-card apply-card glue-card--list"*/
            }
            {/*         href="#"*/
            }
            {/*         onClick={() => handleJobClick('mlDeveloper')}>*/
            }
            {/*        <div className="glue-card__inner">*/
            }
            {/*          <div className="glue-card__content">*/
            }
            {/*            <div className="glue-grid">*/
            }
            {/*              <p*/
            }
            {/*                className="glue-headline glue-headline--headline-5 glue-grid__col glue-grid__col--span-12 glue-grid__col--span-12-md">*/
            }
            {/*                Machine Learning Developer </p>*/
            }
            {/*              <p*/
            }
            {/*                className="glue-caption glue-grid__col glue-grid__col--span-4 glue-grid__col--span-12-md">Project*/
            }
            {/*                Drive</p>*/
            }
            {/*            </div>*/
            }
            {/*            {expandedJob === 'mlDeveloper' && (*/
            }
            {/*              <div className="job-description__details">*/
            }
            {/*                <p>*/
            }
            {/*                  Are you interested in practically applying Machine Learning, maybe because*/
            }
            {/*                  you are studying mathematics or computer science? You are curious about*/
            }
            {/*                  Reinforcement Learning based driving control? Then this one is for you!*/
            }
            {/*                  Required skills: knowledge about machine learning and Python programming.*/
            }
            {/*                  Ideally you have some prior experience with PyTorch or some other machine*/
            }
            {/*                  learning framework.*/
            }
            {/*                </p>*/
            }
            {/*              </div>*/
            }
            {/*            )}*/
            }
            {/*          </div>*/
            }
            {/*        </div>*/
            }
            {/*      </a>*/
            }
            {/*    </li>*/
            }
            {/*    <li*/
            }
            {/*      className="glue-grid__col glue-grid__col--span-18 glue-grid__col--span-6-md glue-grid__col--span-18-lg glue-grid__col--span-18-xl">*/
            }
            {/*      <a className="glue-card apply-card glue-card--list"*/
            }
            {/*         href="#"*/
            }
            {/*         onClick={() => handleJobClick('electronicsEngineer')}>*/
            }
            {/*        <div className="glue-card__inner">*/
            }
            {/*          <div className="glue-card__content">*/
            }
            {/*            <div className="glue-grid">*/
            }
            {/*              <p*/
            }
            {/*                className="glue-headline glue-headline--headline-5 glue-grid__col glue-grid__col--span-12 glue-grid__col--span-12-md">*/
            }
            {/*                Electronics Engineer </p>*/
            }
            {/*              <p*/
            }
            {/*                className="glue-caption glue-grid__col glue-grid__col--span-4 glue-grid__col--span-12-md">Project*/
            }
            {/*                Drive</p>*/
            }
            {/*            </div>*/
            }
            {/*            {expandedJob === 'electronicsEngineer' && (*/
            }
            {/*              <div className="job-description__details">*/
            }
            {/*                <p>*/
            }
            {/*                  Are you passionate about the mechanics and electronics of ~1:10 scaled cars?*/
            }
            {/*                  As an electronics engineer, you will be responsible for choosing the right*/
            }
            {/*                  components for our vehicles and for the assembly and integration of the*/
            }
            {/*                  electronics into our cars. Skills that you should have are: a basic*/
            }
            {/*                  understanding of electronics in general, especially related to autonomous*/
            }
            {/*                  vehicles.*/
            }
            {/*                </p>*/
            }
            {/*              </div>*/
            }
            {/*            )}*/
            }
            {/*          </div>*/
            }
            {/*        </div>*/
            }
            {/*      </a>*/
            }
            {/*    </li>*/
            }
            {/*    <li*/
            }
            {/*      className="glue-grid__col glue-grid__col--span-18 glue-grid__col--span-6-md glue-grid__col--span-18-lg glue-grid__col--span-18-xl">*/
            }
            {/*      <a className="glue-card apply-card glue-card--list"*/
            }
            {/*         href="#"*/
            }
            {/*         onClick={() => handleJobClick('softwareEngineer')}>*/
            }
            {/*        <div className="glue-card__inner">*/
            }
            {/*          <div className="glue-card__content">*/
            }
            {/*            <div className="glue-grid">*/
            }
            {/*              <p*/
            }
            {/*                className="glue-headline glue-headline--headline-5 glue-grid__col glue-grid__col--span-12 glue-grid__col--span-12-md">*/
            }
            {/*                Software Engineer </p>*/
            }
            {/*              <p*/
            }
            {/*                className="glue-caption glue-grid__col glue-grid__col--span-4 glue-grid__col--span-12-md">Project*/
            }
            {/*                Drive</p>*/
            }
            {/*            </div>*/
            }
            {/*            {expandedJob === 'softwareEngineer' && (*/
            }
            {/*              <div className="job-description__details">*/
            }
            {/*                <p>*/
            }
            {/*                  You are studying computer science and want some practical applications for*/
            }
            {/*                  what you learned in your courses? As a software engineer, you will develop*/
            }
            {/*                  high-level software for testing and simulation of our drive controller as*/
            }
            {/*                  well as low-level software for the drive controller itself. Required skills:*/
            }
            {/*                  knowledge about Python programming and Linux.*/
            }
            {/*                </p>*/
            }
            {/*              </div>*/
            }
            {/*            )}*/
            }
            {/*          </div>*/
            }
            {/*        </div>*/
            }
            {/*      </a>*/
            }
            {/*    </li>*/
            }
            {/*  </ul>*/
            }
            {/*</div>*/
            }
        </div>
    )
}

export default Apply;