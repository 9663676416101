import React, {useState} from 'react';
import {ReactComponent as XLogo} from './logos/x.svg';
import {ReactComponent as InstaLogo} from './logos/instagram.svg';
import {ReactComponent as LinkedinLogo} from './logos/linkedin.svg';
import {ReactComponent as GithubLogo} from './logos/github.svg';

const Footer = () => {
    return (
        <footer className="phx-footer glue-footer">
            <div className="glue-footer__site-links">
                <div className="glue-social__group">
                    <ul className="glue-social__list" role="list">
                        <li className="glue-social__item">
                            <a className="glue-social__link"
                               href="https://twitter.com/tum_phoenix" title="Follow us on Twitter"
                               target="_blank" rel="noopener">
                                <XLogo
                                    className="glue-icon glue-icon--32px glue-icon--social glue-icon--color-sharelink"
                                    width="69"
                                    height="69" viewBox="0 0 512 512" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                </XLogo>
                            </a>
                        </li>
                        <li className="glue-social__item">
                            <a className="glue-social__link"
                               href="https://www.instagram.com/tumphoenixrobotics" title="Follow us on Instagram"
                               target="_blank" rel="noopener">
                                <InstaLogo
                                    className="glue-icon glue-icon--32px glue-icon--social glue-icon--color-instagram"
                                    width="69"
                                    height="69" viewBox="0 0 69 69" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                </InstaLogo>
                            </a>
                        </li>
                        <li className="glue-social__item">
                            <a className="glue-social__link"
                               href="https://www.linkedin.com/company/phoenix-robotics" title="Follow us on LinkedIn"
                               target="_blank" rel="noopener">
                                <LinkedinLogo
                                    className="glue-icon glue-icon--32px glue-icon--social glue-icon--color-linkedin"
                                    width="69"
                                    height="69" viewBox="0 0 69 69" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                </LinkedinLogo>
                            </a>
                        </li>
                        <li className="glue-social__item">
                            <a className="glue-social__link"
                               href="https://github.com/tum-phoenix" title="Follow us on Github" target="_blank"
                               rel="noopener">
                                <GithubLogo
                                    className="glue-icon glue-icon--32px glue-icon--social glue-icon--color-sharelink"
                                    width="69"
                                    height="69" viewBox="0 0 69 69" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                </GithubLogo>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;