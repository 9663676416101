import {Link} from "react-router-dom";

function Drive() {
  return (
      <div className="glue-page bookmark">
        <div className="page-title">
          <h1 className="page-title__title glue-headline glue-headline--headline-2">
            Autonomous Drive
          </h1>

          <div className="page-title__footer">
            <p>
              We do miniature autonomous driving, using a 1:10 scale model vehicle that is equipped
              with
              all necessary sensors, actuators, electronic components and computers.
              Our technology is very similar to what is being used in real autonomous cars.
              Thus, we are able to gain experience with autonomous automotive systems while still at
              the
              university.
              To build a car that can work perfectly, it is crucial that we as an interdisciplinary
              team
              work closely together in the fields of mechanics, electronics and software.
              This year, we are looking forward to prove our technology at the F1Tenth Racing
              Competition.
            </p>
          </div>
        </div>
        <div className="page-cover glue-grid page-cover--inset page-cover--right">
          <picture
              className="page-cover__image glue-grid__col glue-grid__col--span-7 glue-grid__col--span-12-md">
            <img alt="" height="462"
                 src="images/drive.jpg"
                 width="462"/>
          </picture>
          <div
              className="page-cover__content glue-grid__col glue-grid__col--span-11 glue-grid__col--span-12-md">
            <h1 className="page-cover__title glue-headline glue-headline--headline-3">F1Tenth Racing Competition</h1>
            <div className="page-cover__description">
              <p>
                TBD
              </p>
            </div>

            {/*<section className="glue-social glue-social--zippy share share--left"*/}
            {/*         id="share-3bacd3db-5c9b-4bfd-8260-5d1e628af2d9">*/}
            {/*  <div className="glue-social__group">*/}
            {/*    <Link to="/f1tenth"*/}
            {/*          className="glue-button glue-button--medium-emphasis cta promo__cta">*/}
            {/*      Learn more*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*</section>*/}
          </div>
        </div>
        <div className="page-cover glue-grid page-cover--inset page-cover--right">
          <picture
              className="page-cover__image glue-grid__col glue-grid__col--span-7 glue-grid__col--span-12-md">
            <img alt="" height="462"
                 src="images/drive.jpg"
                 width="462"/>
          </picture>
          <div
              className="page-cover__content glue-grid__col glue-grid__col--span-11 glue-grid__col--span-12-md">
            <h1 className="page-cover__title glue-headline glue-headline--headline-3">Bosch Future
              Mobility Challenge</h1>
            <div className="page-cover__description">
              <p>
                As part of the Bosch Future Mobility Challenge, we are working diligently to develop
                state-of-the-art algorithms to navigate our 1/10 scale vehicles in a miniature smart
                city environment. Our team is made up of talented and passionate students from the
                Technical University of Munich, who are committed to pushing the boundaries of
                what&rsquo;s possible in the world of autonomous driving and connectivity. With the
                support of the Bosch Engineering Center Club and academic professors, we are
                developing
                cutting-edge algorithms and technologies that will pave the way for the future of
                mobility.
              </p>
            </div>

            {/*<section className="glue-social glue-social--zippy share share--left"*/}
            {/*         id="share-3bacd3db-5c9b-4bfd-8260-5d1e628af2d9">*/}
            {/*  <div className="glue-social__group">*/}
            {/*    <Link to="/future-mobility-challenge"*/}
            {/*          className="glue-button glue-button--medium-emphasis cta promo__cta">*/}
            {/*      Learn more*/}
            {/*    </Link>*/}
            {/*  </div>*/}
            {/*</section>*/}
          </div>
        </div>
        <div className="page-cover glue-grid page-cover--inset page-cover--right">
          <picture
              className="page-cover__image glue-grid__col glue-grid__col--span-7 glue-grid__col--span-12-md">
            <img alt="" height="462"
                 src="images/carolo_cup_team.jpg"
                 width="462"/>
          </picture>
          <div
              className="page-cover__content glue-grid__col glue-grid__col--span-11 glue-grid__col--span-12-md">
            <h1 className="page-cover__title glue-headline glue-headline--headline-3">Carolo Cup</h1>
            <div className="page-cover__description">
              <p>
                The "Carolo-Cup" has been an international student competition of the Technical University
                of Braunschweig and took place annually until 2019. The challenge was
                to achieve best possible vehicle guidance in various disciplines, such as "Driving
                without obstacles", "Driving with obstacles and traffic signs" and "Finding a parking
                space and parking". The teams must also give a presentation on their overall vehicle
                concept, which was then evaluated by a jury of industry and science experts.
              </p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Drive;