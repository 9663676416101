import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/home/Home";
import HeaderBar from "./components/headerBar/HeaderBar";
import Footer from "./components/footer/Footer";
import Flight from "./pages/flight/Flight";
import Drive from "./pages/drive/Drive";
import Apply from "./pages/apply/Apply";

export default function Routes() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <HeaderBar />
          <Outlet />
          <Footer />
        </>
      ),
      children: [
        {
          path: "",
          element: <Navigate replace to="/home" />,
        },
        {
          path: "/home",
          element: <Home />,
          children: [],
        },
        {
          path: "/flight",
          element: <Flight />,
          children: [],
        },
        {
          path: "/drive",
          element: <Drive />,
          children: [],
        },
        {
          path: "/apply",
          element: <Apply />,
          children: [],
        },
      ],
    },
  ]);

  return <RouterProvider router={routes} />;
}
