import {Link} from "react-router-dom";

function Flight() {
  return (
    <div className="glue-page bookmark">
      <div className="page-title">
        <h1 className="page-title__title glue-headline glue-headline--headline-2">
          Autonomous Flight<br />
          (currently paused)
        </h1>

        <div className="page-title__footer">
          <p>
            In the flight team, we build autonomous drones for various applications, from warehouses
            to
            even augmented reality games.
            For this, we design and build our own drones from scratch, from the frame and motors to
            the
            flight controller software.
          </p>
        </div>
      </div>
      <div className="page-cover glue-grid page-cover--inset page-cover--right">
        <picture
          className="page-cover__image glue-grid__col glue-grid__col--span-7 glue-grid__col--span-12-md">
          <img alt="" height="462"
               src="images/golden_snitch.jpeg"
               width="462"/>
        </picture>
        <div
          className="page-cover__content glue-grid__col glue-grid__col--span-11 glue-grid__col--span-12-md">
          <h1 className="page-cover__title glue-headline glue-headline--headline-3">Golden
            Snitch</h1>
          <div className="page-cover__description">
            <p>
              Do you know the "Golden Snitch" from Harry Potter? Similar to it, this drone is
              designed to be used in a game, in which players have to catch or avoid touching the
              drone. The players wear AR glasses so that the drone can be displayed as any object,
              for instance, a magical flying snitch. For this purpose, the drone has an autonomous
              flight controller that is connected to sensors that perceive the surroundings and
              recognize objects and the position of the players using Machine Learning. The drone is
              fitted with programmable LEDs for visual effects in addition to the AR world. To
              protect players and provide better crash resistance, the drone is surrounded by a
              sturdy carbon fiber cage.
              We are doing this project in cooperation with the chair for Augmented Reality and the
              chair for Flight System Dynamics.
            </p>
          </div>
        </div>
      </div>
      {/*<div className="page-cover glue-grid page-cover--inset page-cover--right">*/}
      {/*  <picture*/}
      {/*    className="page-cover__image glue-grid__col glue-grid__col--span-7 glue-grid__col--span-12-md">*/}
      {/*    <img alt="" height="462"*/}
      {/*         src="images/heli_drone.png"*/}
      {/*         width="462"/>*/}
      {/*  </picture>*/}
      {/*  <div*/}
      {/*    className="page-cover__content glue-grid__col glue-grid__col--span-11 glue-grid__col--span-12-md">*/}
      {/*    <h1 className="page-cover__title glue-headline glue-headline--headline-3">Helicopter Drone</h1>*/}
      {/*    <div className="page-cover__description">*/}
      {/*      <p>*/}
      {/*        TBD*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="page-cover glue-grid page-cover--inset page-cover--right">*/}
      {/*  <picture*/}
      {/*    className="page-cover__image glue-grid__col glue-grid__col--span-7 glue-grid__col--span-12-md">*/}
      {/*    <img alt="" height="462"*/}
      {/*         src="images/flight.jpg"*/}
      {/*         width="462"/>*/}
      {/*  </picture>*/}
      {/*  <div*/}
      {/*    className="page-cover__content glue-grid__col glue-grid__col--span-11 glue-grid__col--span-12-md">*/}
      {/*    <h1 className="page-cover__title glue-headline glue-headline--headline-3">Tarot</h1>*/}
      {/*    <div className="page-cover__description">*/}
      {/*      <p>*/}
      {/*        TBD*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default Flight;