import {Link} from "react-router-dom";

function Home() {
  return (
    <div className="glue-page bookmark">
      <div className="promo promo--full-width promo--icon promo--inverted promo--large-text">
        <picture className="promo__background-image">
          <img alt="" height="729" role="presentation"
               src="images/phoenix_teaser.jpg"
               width="1296"/>
        </picture>
        <div className="promo__inner">
          <div className="promo__text">
            <h2 className="glue-headline glue-headline--headline-2 promo__headline">
              Prototyping the Next Generation <br/>of <span
              className="emph">Autonomous Systems</span>
            </h2>
          </div>
          <Link className="glue-button glue-button--medium-emphasis cta promo__cta" to="/apply">
            Join us
          </Link>
        </div>
      </div>
      <ul className="glue-grid glue-cards cards">
        <li className="glue-grid__col glue-grid__col--span-9
                            glue-grid__col--span-9-lg
                            glue-grid__col--span-9-xl">
          <Link className="glue-card card"
                to="/drive">
            <div className="glue-card__inner">
              <picture className="glue-card__asset">
                <img alt="" height="462"
                     src="images/drive.jpg"
                     width="462"/>
              </picture>
              <div className="glue-card__content">
                <p className="glue-headline glue-headline--headline-5">Project Drive</p>
                <p className="glue-card__description">
                  In the award-winning Drive Team, we are working on a 1:10 scale model car,
                  equipped
                  with various sensors and an on-board computer to enable fully autonomous driving.
                  We are regularly participating in competitions, where we demonstrate the
                  autonomous
                  capabilities of our cars.
                </p>
              </div>
            </div>
          </Link>
        </li>
        <li className="glue-grid__col glue-grid__col--span-9
                            glue-grid__col--span-9-lg
                            glue-grid__col--span-9-xl">
          <Link className="glue-card card"
                to="/flight">
            <div className="glue-card__inner">
              <picture className="glue-card__asset">
                <img alt="" height="462"
                     src="images/flight.jpg"
                     width="462"/>
              </picture>
              <div className="glue-card__content">
                <p className="glue-headline glue-headline--headline-5">Project Flight</p>
                <p className="glue-card__description">
                  Although currently inactive, the flight team develops autonomous quadcopter drones
                  such as one for use inside an AR game of Quidditch.
                </p>
              </div>
            </div>
          </Link>
        </li>
      </ul>
      <h2 className="glue-headline glue-headline--headline-2">Our Goals</h2>
      <div className="glue-grid promo-card-group">
        <div className="glue-grid__col glue-grid__col--span-6 glue-grid__col--span-12-md">
          <div className="goals goals1">
            <div className="goals__inner">
              <div className="promo__text">
                <h2
                  className="glue-headline glue-headline--headline-3 promo__headline">Gain practical
                  experience</h2>
                <div className="promo__description"><p>At Phoenix Robotics,
                  you can apply your knowledge in a practical setting to build autonomous systems. Regardless if you are studying
                  computer science, engineering, physics, mathematics or business administration, it
                  will be a great addition to your studies.</p></div>
              </div>
            </div>
          </div>
        </div>
        <div className="glue-grid__col glue-grid__col--span-6 glue-grid__col--span-12-md">
          <div className="goals goals2">
            <div className="goals__inner">
              <div className="promo__text">
                <h2 className="glue-headline glue-headline--headline-3 promo__headline">Socialize
                  with like-minded people</h2>
                <div className="promo__description"><p>We offer you the
                  chance to work together with a committed team of students from various disciplines
                  and get to know like-minded people. For this purpose, we organize regular
                  workshops and also social events.</p></div>
              </div>
            </div>
          </div>
        </div>
        <div className="glue-grid__col glue-grid__col--span-6 glue-grid__col--span-12-md">
          <div className="goals goals3">
            <div className="goals__inner">
              <div className="promo__text">
                <h2 className="glue-headline glue-headline--headline-3 promo__headline">Strengthen
                  your soft skills</h2>
                <div className="promo__description"><p>
                  In addition to technical skills, you will also gain essential skills for your
                  future
                  career, such as communication skills and project management.
                </p></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="glue-headline glue-headline--headline-2">Our Partners</h2>
      <ul className="glue-grid glue-cards sponsors">
        <li
          className="glue-grid__col glue-grid__col--span-4 glue-grid__col--span-12-md sponsor-icon">
          <a href="https://www.exotec.com/">
            <img src="logos/exotec.svg"/>
          </a>
        </li>
        <li
          className="glue-grid__col glue-grid__col--span-4 glue-grid__col--span-12-md sponsor-icon">
          <a href="https://www.mos.ed.tum.de/en/avs/home/">
            <img src="logos/fsd.jpg"/>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Home;