import React, {useState} from 'react';
import {useViewport} from "../../context/viewportContext";
import {Link, useLocation} from "react-router-dom";

function HeaderBar() {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("/home");
  const [menuExpanded, setMenuExpanded] = useState(false);
  const { width } = useViewport();

  return (
    <header className="glue-header glue-header--no-cta glue-header--single phx-header"
            aria-expanded="false">
      <a href="#content" className="glue-header__link glue-header__skip-content">Jump to Content</a>
      <div className="glue-header__bar glue-header__bar--mobile">
        <div className="glue-header__tier">
          <div className="glue-header__container">
            <div className="glue-header__lock-up">
              <div className="glue-header__hamburger">
                <button className="glue-header__drawer-toggle-btn"
                        aria-label="Open the navigation drawer" aria-controls="glue-drawer-af2b22"
                        aria-expanded="false" aria-haspopup="true">
                  <span className="material-symbols-outlined">menu</span>
                </button>
              </div>

              <div className="phx-header__search-wrapper phx-header__search-wrapper--mobile">
                <div className="glue-header__logo">
                  <Link className="glue-header__logo-link" to="/home"
                     title="Phoenix Robotics">
                    <div className="glue-header__logo-container">
                      <svg aria-hidden="true" className="glue-icon glue-header__logo-svg"
                           role="presentation">
                        <img src="logos/phoenix.svg" alt="Phoenix Robotics"/>
                      </svg>
                      <svg aria-hidden="true" className="glue-icon glue-header__logo-svg"
                           role="presentation">
                        <img src="logos/phoenix.svg" alt="Phoenix Robotics"/>
                      </svg>
                    </div>
                    <span className="glue-header__logo--product">Phoenix Robotics</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="glue-header__bar glue-header__bar--desktop glue-header__drawer"
           id="glue-drawer-af2b22" style={{position: 'static', top: '-64px'}}>
        <div className="glue-header__tier">
          <div className="glue-header__container">
            <div className="glue-header__lock-up">
              <div className="glue-header__logo">
                <Link className="glue-header__logo-link" to="/home"
                   title="Phoenix Robotics">
                  <div className="glue-header__logo-container">
                    <img src="logos/phoenix.svg" aria-hidden="true" role="presentation" className="glue-icon glue-header__logo-svg"/>
                  </div>
                  <span className="glue-header__logo--product">Phoenix Robotics</span>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="glue-header__container glue-header__container--linkbar phx-header__search-wrapper phx-header__search-wrapper--desktop">
            <nav className="glue-header__link-bar">
              <ul className="glue-header__list">
                <li className={`glue-header__item ${location.pathname === '/drive' ? 'glue-header__item--active' : ''}`}>
                  <Link className="glue-header__link" to="/drive">
                    Drive
                  </Link>
                </li>
                <li className={`glue-header__item ${location.pathname === '/flight' ? 'glue-header__item--active' : ''}`}>
                  <Link className="glue-header__link"
                        to="/flight">
                    Flight
                  </Link>
                </li>
                <li
                  className={`glue-header__item ${location.pathname === '/apply' ? 'glue-header__item--active' : ''}`}>
                  <Link className="glue-header__link"
                        to="/apply">
                    Apply
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div className="glue-header__drawer-backdrop phx-header__backdrop"></div>
    </header>
  );
}

export default HeaderBar;